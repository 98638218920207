<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Cliente (Encomenda)" : "Cadastrar Cliente (Encomenda)" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-switch
                v-model="data.pj"
                :label="`${data.pj ? 'Cadastro com CNPJ' : 'Cadastro com CPF'}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="12" :md="data.pj ? 6 : 12">
              <span class="primary--text">{{
                data.pj ? "Razão Social *" : "Nome Completo *"
              }}</span>
              <v-text-field
                v-model="data.razao_social_nome"
                :rules="fieldRules"
                required
                :placeholder="`Informe ${
                  data.pj ? 'a razão social' : 'o nome completo'
                }`"
                class="mt-n1"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="6" v-if="data.pj">
              <span class="primary--text">Nome Fantasia</span>
              <v-text-field
                v-model="data.nome_fantasia_sobrenome"
                placeholder="Informe o nome fantasia"
                class="mt-n1"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">{{ data.pj ? "CNPJ" : "CPF" }}</span>
              <v-text-field
                v-if="data.pj"
                v-model="data.cnpj_cpf"
                placeholder="Informe o cnpj"
                class="mt-n1"
                v-mask="'##.###.###/####-##'"
                required
              />
              <v-text-field
                v-else
                v-model="data.cnpj_cpf"
                placeholder="Informe o cpf"
                class="mt-n1"
                v-mask="'###.###.###-##'"
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">{{ data.pj ? "IE" : "RG" }}</span>
              <v-text-field
                v-model="data.ie_rg"
                :placeholder="`Informe ${data.pj ? 'a ie' : 'o rg'}`"
                class="mt-n1"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">E-mail</span>
              <v-text-field
                v-model="data.email"
                placeholder="Informe o e-mail"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Telefone *</span>
              <v-text-field
                v-model="data.telefone"
                placeholder="Informe o telefone"
                class="mt-n1"
                v-mask-phone.br
                :rules="fieldRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações de Endereço</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">CEP</span>
              <v-text-field
                v-model="data.cep"
                placeholder="Informe o cep"
                class="mt-n1"
                @blur="getCep(data.cep)"
                :loading="loading"
                v-mask-cep.br
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Logradouro</span>
              <v-text-field
                v-model="data.logradouro"
                placeholder="Informe o logradouro"
                class="mt-n1"
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <span class="primary--text">Número</span>
              <v-text-field
                v-model="data.numero"
                placeholder="Informe o número"
                class="mt-n1"
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Bairro</span>
              <v-text-field
                v-model="data.bairro"
                placeholder="Informe o bairro"
                class="mt-n1"
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">'Código do IBGE</span>
              <v-text-field
                v-model="data.cod_cidade_ibge"
                placeholder="Informe o código do ibge"
                class="mt-n1"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <span class="primary--text">Cidade</span>
              <v-text-field
                v-model="data.cidade"
                placeholder="Informe a cidade"
                class="mt-n1"
                required
              />
            </v-col>
            <v-col cols="12" sm="12" md="2">
              <span class="primary--text">UF</span>
              <v-text-field
                v-model="data.uf"
                placeholder="UF"
                class="mt-n1"
                v-mask="'UU'"
                required
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn text depressed dark :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import BackArrow from "../../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  directives: { mask },
  data: () => ({
    data: {
      razao_social_nome: "",
      nome_fantasia_sobrenome: "",
      cnpj_cpf: "",
      ie_rg: "",
      logradouro: "",
      bairro: "",
      cep: "",
      telefone: "",
      cod_cidade_ibge: "",
      cidade: "",
      uf: "",
      email: "",
      pj: true,
    },
    loading: false,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    emailRules: [(v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!"],
    valid: true,
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 2000,
    },
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.id) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .get(`/encomendas/clientes/${this.id}`)
          .then((response) => {
            this.data = response.data;
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/encomendas/clientes/edit/${this.id}`, this.data) // link da API
          .then((r) => {
            this.data.razao_social_nome =
              this.data.razao_social_nome.toUpperCase();
            this.data.nome_fantasia_sobrenome =
              this.data.nome_fantasia_sobrenome.toUpperCase();
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success darken-1",
                text: r.data.success,
              });
              this.$router.push("/encomendas/clientes");
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "error darken-1",
                text: r.data.error,
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: e,
            });
          });
      } else {
        this.$http
          .post("/encomendas/clientes/create", this.data)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success",
                text: r.data.success,
              });
              this.$router.push("/encomendas/clientes");
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "warning",
                text: r.data.warning,
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error",
              text: e,
            });
          });
      }
    },
    getCep(cep) {
      if (cep.length == 10) {
        this.loading = true;
        cep = cep.replace("-", "");
        cep = cep.replace(".", "");
        axios
          .get("https://viacep.com.br/ws/" + cep + "/json/")
          .then((response) => {
            this.data.logradouro = response.data.logradouro;
            this.data.bairro = response.data.bairro;
            this.data.cidade = response.data.localidade;
            this.data.uf = response.data.uf;
            this.data.cod_cidade_ibge = response.data.ibge;
            this.loading = false;
          })
          .catch((e) => {
            this.message.snacshow = true;
            this.message.type = "warning";
            this.message.text = "Atencão cep não encontrado!" + e;
            this.loading = false;
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
